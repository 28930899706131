import React from "react";
import { graphql } from "gatsby";
import TreeAndShrubServices from "../components/TreeAndShrubServices";

class TreeAndShrubServicesPage extends React.Component {
  render() {
    const { location, data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const services = data.services.edges.map((edge) => edge.node);

    return (
      <TreeAndShrubServices
        location={location}
        siteTitle={siteTitle}
        services={services}
      />
    );
  }
}

export default TreeAndShrubServicesPage;

export const pageQuery = graphql`
  query TreeAndShrubServices {
    site {
      siteMetadata {
        title
      }
    }
    services: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        fields: { slug: { glob: "/services/**" } }
        frontmatter: { tags: { eq: "tree-and-shrub" } }
      }
    ) {
      edges {
        node {
          ...MarkdownRemarkData
        }
      }
    }
  }
`;

import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Container from "../Container";
import styles from "./Hero.module.scss";

const Hero = ({ className, title }) => (
  <div className={cx(styles.root, className)}>
    <Container>
      <h1 className={styles.title}>{title}</h1>
    </Container>
  </div>
);

Hero.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Hero;

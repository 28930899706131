import PropTypes from "prop-types";
import React from "react";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import PrettyList from "../common/PrettyList";
import styles from "./TreeAndShrubServices.module.scss";

class TreeAndShrubServices extends React.Component {
  render() {
    const { location, siteTitle, services } = this.props;
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title="Tree & Shrub Services"
          keywords={[
            "tree services",
            "shrub services",
            services.map((node) => node.frontmatter.title),
          ]}
        />
        <Hero title="Tree and Shrub Services" />
        <PrettyList
          className={styles.services}
          title={<h2 className="sr-only">Services</h2>}
          items={services.map((node) => ({
            title: node.frontmatter.title || node.fields.slug,
            description: node.frontmatter.description || node.excerpt,
            slug: node.fields.slug,
          }))}
        />
      </Layout>
    );
  }
}

TreeAndShrubServices.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
};

export default TreeAndShrubServices;
